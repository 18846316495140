export const apiKey = () => 'mharyr0adm153xb3byihhlnti9i92y4jlp7nifevrayyoird'

export const getConfig = () => ({
  skin: 'oxide-dark',
  content_css: 'dark',
  height: 300,
  menubar: false,
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount',
  ],
  toolbar:
      'undo redo | formatselect | bold italic backcolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | help',
})

export default { apiKey, getConfig }
