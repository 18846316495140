<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-form
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="scorer_type"
                :name="$t('labels.type')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.type')"
                >
                  <v-select
                    v-model="scorer_type"
                    :dir="$store.state.appConfig.layout.direction"
                    label="label"
                    :options="match_types"
                    :reduce="item => item.value"
                    :placeholder="$t('labels.type')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              v-if="scorer_type === 'league'"
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="league_id"
                :name="$t('labels.select_league')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.select_league')"
                >
                  <v-select
                    v-model="form.league_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="title_label"
                    :options="leagues"
                    :reduce="item => item.id"
                    :placeholder="$t('labels.select_league')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="scorer_type === 'championship'"
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="championship_id"
                :name="$t('labels.select_championship')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.select_championship')"
                >
                  <v-select
                    v-model="form.championship_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="championships"
                    :reduce="item => item.id"
                    :placeholder="$t('labels.select_championship')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="teams.length"
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="team_id"
                :name="$t('labels.team')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.team')"
                >
                  <v-select
                    v-model="form.team_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="teams"
                    :reduce="item => item.id"
                    :placeholder="$t('labels.team')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="players.length"
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="player_id"
                :name="$t('labels.player')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.player')"
                >
                  <v-select
                    v-model="form.player_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="players"
                    :reduce="item => item.id"
                    :placeholder="$t('labels.player')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="season_id"
                :name="$t('labels.season')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.season')"
                >
                  <v-select
                    v-model="form.season_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="seasons"
                    :reduce="item => item.id"
                    :placeholder="$t('labels.season')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.number_of_goals')"
                vid="number_of_goals"
                rules="required|numeric"
              >
                <b-form-group
                  :label="$t('labels.number_of_goals')"
                >
                  <b-form-input
                    v-model="form.number_of_goals"
                    :placeholder="$t('labels.number_of_goals')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.number_of_shots')"
                vid="number_of_shots"
                rules="numeric"
              >
                <b-form-group
                  :label="$t('labels.number_of_shots')"
                >
                  <b-form-input
                    v-model="form.number_of_shots"
                    :placeholder="$t('labels.number_of_shots')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.number_of_matches')"
                vid="number_of_matches"
                rules="numeric"
              >
                <b-form-group
                  :label="$t('labels.number_of_matches')"
                >
                  <b-form-input
                    v-model="form.number_of_matches"
                    :placeholder="$t('labels.number_of_matches')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.assists')"
                vid="assists"
                rules="numeric"
              >
                <b-form-group
                  :label="$t('labels.assists')"
                >
                  <b-form-input
                    v-model="form.assists"
                    :placeholder="$t('labels.assists')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- checkbox -->
            <b-col cols="12">
              <b-card-text class="mb-0">
                {{ $t('labels.status') }}
              </b-card-text>
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
            </b-col>
            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      leagues: [],
      championships: [],
      teams: [],
      players: [],
      seasons: [],
      match_types: [],
      scorer_type: null,
      form: {
        league_id: null,
        championship_id: null,
        player_id: null,
        team_id: null,
        number_of_goals: null,
        number_of_shots: null,
        number_of_matches: null,
        assists: null,
        season_id: null,
        status: 'active',
      },
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.league_id': function (val) {
      this.getTeams(`?league_id=${val}`)
    },
    // eslint-disable-next-line func-names
    'form.championship_id': function (val) {
      this.getTeams(`?championship_id=${val}`)
    },
    // eslint-disable-next-line func-names
    'form.team_id': function (val) {
      this.getPlayers(`?team_id=${val}`)
    },
  },
  created() {
    this.getLeagues()
    this.getChampionships()
    this.getSeasons()
    this.getMatchTypes()
    if (this.isEdit) {
      this.loadData()
    }
  },
  methods: {
    getLeagues() {
      this.axios.get('/lists/League')
        .then(res => {
          this.leagues = res.data
        })
    },
    getChampionships() {
      this.axios.get('/lists/Championship')
        .then(res => {
          this.championships = res.data
        })
    },
    getMatchTypes() {
      this.axios.get('/lists/match/types')
        .then(res => {
          this.match_types = res.data
        })
    },
    getSeasons() {
      this.axios.get('/lists/Season')
        .then(res => {
          this.seasons = res.data
        })
    },
    getTeams(id) {
      this.axios.get(`/lists/Team${id}`)
        .then(res => {
          this.teams = res.data
        })
    },
    getPlayers(val) {
      this.axios.get(`/lists/team/players${val}`)
        .then(res => {
          this.players = res.data
        })
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            league_id: data.league_id,
            championship_id: data.championship_id,
            player_id: data.player_id,
            team_id: data.team_id,
            number_of_goals: data.number_of_goals,
            number_of_shots: data.number_of_shots,
            number_of_matches: data.number_of_matches,
            assists: data.assists,
            season_id: data.season_id,
            status: data.status,
          }
          this.scorer_type = data.scorer_type
        })
    },
  },

}
</script>
